import React from 'react'
import PropTypes from 'prop-types'

const Paragraph = ({children, className}) => (
    <p className={"leading-loose text-sm mb-8" + (className ? ` ${className}` : "")}>
        {children}
    </p>
)

Paragraph.propTypes = {
    children: PropTypes.node.isRequired,
}

  export default Paragraph
