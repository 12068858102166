import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Paragraph from "../components/Paragraph"
import Seo from "../components/seo"
import Title from "../components/Title"

const AboutPage = ({data}) => {
  const {markdownRemark: markdown} = data
    const {medlemmer} = markdown.frontmatter
  
  return <Layout>
    <Seo title={markdown.frontmatter.title} />
    
    <Title className="text-center">
      {markdown.frontmatter.title}
    </Title>

    <div className="lg:flex lg:flex-row lg:justify-between">
      {
          medlemmer.map((item, index) => {
                  const image = getImage(item.image)
                  return <div key={index} className="mt-8 mb-16 flex flex-col lg:px-8">
                      <GatsbyImage className="self-center lg:h-96" image={image} alt={item.name} />
                      <div className="px-4">
                          <h2 className="text-xl mt-4">{item?.name}</h2>
                          <Paragraph>
                              {item?.description}
                          </Paragraph>
                      </div>
                  </div>
              }
          )    
      }
    </div>

  </Layout>
}

export const query = graphql`
query {
  markdownRemark(fields: { slug: { eq: "/about/" } }) {
    frontmatter {
      title
      medlemmer{
          description
          name
            image {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
      }
    }
  }
}
`

export default AboutPage
